import { Link } from "react-router-dom";

function BookOnlineButton({ className }) {
  return (
    <Link
      to="/contact-form"
      style={{
        textDecoration: "none",
        width: "min-content",
        height: "min-content",
      }}
    >
      <button className={"book-online-button " + className}>
        GET A QUOTE
        <svg
          fill="currentColor"
          viewBox="0 0 24 24"
          className="book-online-button__icon"
        >
          <path
            clipRule="evenodd"
            d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zm4.28 10.28a.75.75 0 000-1.06l-3-3a.75.75 0 10-1.06 1.06l1.72 1.72H8.25a.75.75 0 000 1.5h5.69l-1.72 1.72a.75.75 0 101.06 1.06l3-3z"
            fillRule="evenodd"
          ></path>
        </svg>
      </button>
    </Link>
  );
}

export default BookOnlineButton;
